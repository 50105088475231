import { ILicense } from "../../db/models"
import fetchPartnerDashboardService from "../../service/fetchPartnerDashboardService"

async function getPartnerLicenses() {
  const res = await fetchPartnerDashboardService(
    "GET",
    null,
    "general/licenses"
  )
  return res.licenses as ILicense[]
}

async function savePartnerLicense(
  licenseName: string,
  licenseRequest: Partial<ILicense>
) {
  const res = await fetchPartnerDashboardService(
    "PATCH",
    licenseRequest,
    `general/license/${licenseName}`
  )
  return res
}

export { getPartnerLicenses, savePartnerLicense }
